/*
*
*
* HammerJS - Handling behavi
*
* */

var myElement = document.getElementById('sceneWrapper');
delete Hammer.defaults.cssProps.userSelect;
var hammertimeBarb = new Hammer(myElement);


hammertimeBarb.get('pinch').set({ enable: false });
hammertimeBarb.get('rotate').set({ enable: false });
hammertimeBarb.get('tap').set({ enable: true });
hammertimeBarb.get('doubletap').set({ enable: false });
hammertimeBarb.get('press').set({ enable: false });
hammertimeBarb.get('pan').set({ enable: false });

//hammertimeBarb.get('pan').set({ direction: Hammer.DIRECTION_VERTICAL, threshold: 10 });
hammertimeBarb.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL, threshold: 10 });


hammertimeBarb.on('pandown swipedown', function(ev) {
    ev.preventDefault();
    if (feedbackFlagIndicator === true) {

        if (feedbackEndFlagIndicator === true ) {
            timeline_feedback.reverse();
            prevFeed();
        } else if( feedbackEndFlagIndicator === false){
            timeline_main.reverse();
        }
    } else {
        timeline_main.reverse();
    }

});
hammertimeBarb.on('panup swipeup', function(ev) {
    ev.preventDefault();
    if (feedbackFlagIndicator === true) {

        if (feedbackEndFlagIndicator === false) {
            timeline_feedback.play();
            nextFeed();
        } else if (feedbackEndFlagIndicator === true) {
            timeline_main.play();
        }
    } else {
        timeline_main.play();
    }
});



