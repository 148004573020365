
/*
 *
 * Section 4 animation
 *
 * */

var timeline_s4 = new TimelineMax({reversed: false, paused: true});

timeline_s4
    .staggerFrom('#stayintouch', 0.55, {y: '-=40px', autoAlpha: 0, ease: Power4.easeInOut}, 0.1, "Contact form")
    .staggerFrom("#contactSubmit", 0.55, {x: '+=40', autoAlpha: 0, ease: Power4.easeInOut}, 0.1, "Contact form")
    .staggerFrom("#contactText", 0.55, {y: '+=40', autoAlpha: 0, ease: Power4.easeInOut}, 0.1, "Contact form")
    .staggerFrom("#sendImage", 0.55, {scale: 0.5, ease: Power4.easeInOut}, 0.1, "Contact form")
    .staggerFrom("#contactLocation", 0.55, {y: '+=40', autoAlpha: 0, ease: Power4.easeInOut})
    .staggerFrom("#contactName", 0.55, {y: '+=40', autoAlpha: 0, ease: Power4.easeInOut});

