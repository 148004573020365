/*
 *
 * Section 3 animation
 *
 * */

var timeline_s3 = new TimelineMax({reversed: false, paused: true});
var timeline_s3_mobile = new TimelineMax({reversed: false, paused: true});

function timeline_s33() {

    if(jQuery(window).width() <= 768){


        timeline_s3_mobile.staggerFrom("#specialEvent", 0.4, {
            autoAlpha: 0,
            x: "-=50",
            ease: Back.easeOut.config(1.7)
        }, 0.1, "Special event")
            .staggerFrom("#specialEventDecr", 0.4, {
                autoAlpha: 0,
                y: "+=50",
                ease: Power4.easeInOut
            }, 0.1, "Special event")
            .staggerFrom("#quality", 0.4, {
                autoAlpha: 0,
                x: "-=50",
                ease: Back.easeOut.config(1.7)
            }, 0.1, "Quality event")
            .staggerFrom("#qualityDecr", 0.4, {
                autoAlpha: 0,
                y: "+=50",
                ease: Power4.easeInOut
            }, 0.1, "Quality event");


    }else{
        timeline_s3.staggerFrom("#specialEvent", 0.4, {
            autoAlpha: 0,
            x: "-=50",
            ease: Back.easeOut.config(1.7)
        }, 0.1, "Special event")
            .staggerFrom("#specialEventDecr", 0.4, {
                autoAlpha: 0,
                y: "+=50",
                ease: Power4.easeInOut
            }, 0.1, "Special event")
            .staggerFrom("#styleAdvice", 0.4, {
                autoAlpha: 0,
                x: "-=50",
                ease: Back.easeOut.config(1.7)
            }, 0.1, "Advice event")
            .staggerFrom("#styleAdviceDecr", 0.4, {
                autoAlpha: 0,
                y: "+=50",
                ease: Power4.easeInOut
            }, 0.1, "Advice event")
            .staggerFrom("#convenience", 0.4, {
                autoAlpha: 0,
                x: "-=50",
                ease: Back.easeOut.config(1.7)
            }, 0.1, "Convenience event")
            .staggerFrom("#convenienceDecr", 0.4, {
                autoAlpha: 0,
                y: "+=50",
                ease: Power4.easeInOut
            }, 0.1, "Convenience event")
            .staggerFrom("#quality", 0.4, {
                autoAlpha: 0,
                x: "-=50",
                ease: Back.easeOut.config(1.7)
            }, 0.1, "Quality event")
            .staggerFrom("#qualityDecr", 0.4, {
                autoAlpha: 0,
                y: "+=50",
                ease: Power4.easeInOut
            }, 0.1, "Quality event");


    }
}


timeline_s33();

