/*jQuery(document).ready(function ($) {
 $(function() {

 // Get the form.
 var form = $('#contactForm');

 // Get the messages div.
 var formMessages = $('#form-messages');

 // Set up an event listener for the contact form.
 $(form).submit(function(e) {
 // Stop the browser from submitting the form.
 e.preventDefault();

 // Serialize the form data.
 var formData = $(form).serialize();

 // Submit the form using AJAX.
 $.ajax({
 type: 'POST',
 url: $(form).attr('action'),
 data: formData
 })
 .done(function(response) {
 // Make sure that the formMessages div has the 'success' class.
 $(formMessages).removeClass('error');
 $(formMessages).addClass('success');

 // Set the message text.
 $(formMessages).text(response);
 tllol.play();
 setInterval(function(){
 // Clear the form.
 $('#name').val('');
 $('#email').val('');
 $('#message').val('');
 tllol.reverse();
 }, 6000);

 })
 .fail(function(data) {
 // Make sure that the formMessages div has the 'error' class.
 $(formMessages).removeClass('success');
 $(formMessages).addClass('error');

 // Set the message text.
 if (data.responseText !== '') {
 $(formMessages).text(data.responseText);

 } else {
 $(formMessages).text('Oops! An error occured and your message could not be sent.');
 }
 });

 });

 });


 });
 */

/*

 Quote form validator
 */

jQuery(document).ready(function ($) {
    $(function () {

        // Get the form.
        var form = $('#qForm');

        // Get the messages div.
        var formMessages = $('#quote-form-messages');

        // Set up an event listener for the contact form.
        $(form).submit(function (e) {
            // Stop the browser from submitting the form.
            e.preventDefault();

            var flag = false;
            $('.qFromInput').each(function () {
                if (!$(this).val()) {
                    $(this).addClass('iAmEmpty');
                    flag = true;

                } else {

                }
            });
            if (flag) {
                formMessages.addClass('error');
                formMessages.html('Some fields are empty. <br /> Please fill them in too.');

                errorTimeline.play();
                setTimeout(function () {

                    errorTimeline.reverse();
                    TlForms.resume(0);

                }, 6000);
                flag = false;
            }
            else {

                console.log("Submitting the form, success!");

                // Serialize the form data.
                var formData = $(form).serialize();

                // Submit the form using AJAX.
                $.ajax({
                    type: 'POST',
                    url: $(form).attr('action'),
                    data: formData
                })
                    .done(function (response) {
                        // Make sure that the formMessages div has the 'success' class.
                        $(formMessages).removeClass('error');
                        $(formMessages).addClass('success');

                        stars();
                        successTimeline.play();
                        // Set the message text.
                        $(formMessages).html(response);
                        setTimeout(function () {
                            // Clear the form.
                            $('#first_name').val('');
                            $('#company_name').val('');
                            $('#address').val('');
                            $('#heads_number').val('');
                            $('#email').val('');
                            successTimeline.pause(0);
                            showQuoteTl.reverse();
                            TlForms.pause(0);

                            $('div.star').remove();

                        }, 6000);

                    })
                    .fail(function (data) {
                        // Make sure that the formMessages div has the 'error' class.
                        $(formMessages).removeClass('success');
                        $(formMessages).addClass('error');

                        // Set the message text.
                        if (data.responseText !== '') {
                            $(formMessages).text(data.responseText);

                        } else {
                            $(formMessages).text('Oops! An error occured and your message could not be sent.');
                        }
                    });
            }

        });

    });


});