/*
 *
 * Scroll events controller
 *
 */
var scrollOver = false;
var sdTemp = 0;
function Scroller(e) {


    clearTimeout($.data(this, "scrollCheck"));
    $.data(this, "scrollCheck", setTimeout(function () {
        scrollOver = false;
        sdTemp = 0;
    }, 150));

    var SD = isNaN(e) ? e.wheelDelta || -e.detail : e;
    //console.log("Playing");

    //console.log(sdTemp, SD);
    scrollOver = true;

    if (SD < 0) {
        if (sdTemp < SD) {
            return;
        }

        if (feedbackFlagIndicator === true) {

            if (feedbackEndFlagIndicator === false) {
                timeline_feedback.play();
                nextFeed();
            } else if (feedbackEndFlagIndicator === true) {
                timeline_main.play();
            }
        } else {
            timeline_main.play();
        }

    }
    else {
        if (sdTemp > SD) {
            return;
        }
        if (feedbackFlagIndicator === true) {


            if (feedbackStartFlagIndicator === true ) {

                timeline_main.reverse();
            } else if( feedbackStartFlagIndicator === false){
                timeline_feedback.reverse();
                prevFeed();
            }
        } else {
            timeline_main.reverse();
        }
    }
    sdTemp = SD;
}

document.addEventListener("mousewheel", Scroller);
document.addEventListener("DOMMouseScroll", Scroller);