/*
 *
 * Feedback timeline
 *
 * */
var feedbackStartFlagIndicator = false;

var bubleAnimeSpeed = 0.6;
timeline_feedback
    .add("IS1start")
    .to("#IS0", 0.001, {backgroundColor: "transparent", ease: Power3.easeOut,onComplete: function () {
        feedbackStartFlagIndicator = false;
    }, onReverseComplete: function () {
        feedbackStartFlagIndicator = true;
        feedbackEndFlagIndicator = false;
    }})
    .to("#IS1", 0.001, {backgroundColor: "#3b53d1", ease: Power3.easeOut})
    .to('#feedback-1 .br-speech-buble-wrapper__author', bubleAnimeSpeed, {
        autoAlpha: 0,
        x: +100,
        ease: Power4.easeInOut
    }, 0.1, "ee")
    .to('#feedback-1 .br-speech-buble-wrapper__content',  bubleAnimeSpeed, {
        autoAlpha: 0,
        //x: -100,
        ease: Power4.easeInOut, onComplete: function () {
            TweenMax.to('#someFeedback', 1, {autoAlpha: 0})
        }
    }, 0.1, "ee1")

    .to($feedback_1, 0.1, {className: "-=active_buble"})
    .to($feedback_2, 0.1, {className: "+=active_buble"})
    .staggerFrom('#feedback-2 .br-speech-buble-wrapper__author',  bubleAnimeSpeed, {
        autoAlpha: 0,
        x: -100,
        ease: Power4.easeInOut
    }, 0.1, "ee2")
    .staggerFrom('#feedback-2 .br-speech-buble-wrapper__content',  bubleAnimeSpeed, {
        autoAlpha: 0,
        //x: +100,
        ease: Power4.easeInOut
    }, 0.1, "ee2")

    .add("IS1end")
    .addPause()

    .add("IS2start")
    .staggerTo('#feedback-2 .br-speech-buble-wrapper__author',  bubleAnimeSpeed, {
        autoAlpha: 0,
        x: +100,
        ease: Power4.easeInOut
    }, 0.1, "ee3")
    .staggerTo('#feedback-2 .br-speech-buble-wrapper__content',  bubleAnimeSpeed, {
        autoAlpha: 0,
        //x: -100,
        ease: Power4.easeInOut
    }, 0.1, "ee3")

    .to("#IS1", 0.001, {backgroundColor: "transparent", ease: Power3.easeOut})
    .to("#IS2", 0.001, {backgroundColor: "#3b53d1", ease: Power3.easeOut})

    .to($feedback_2, 0.1, {className: "-=active_buble"})
    .to($feedback_3, 0.1, {className: "+=active_buble"})
    .staggerFrom('#feedback-3 .br-speech-buble-wrapper__author', bubleAnimeSpeed, {
        autoAlpha: 0,
        x: -100,
        ease: Power4.easeInOut
    }, 0.1, "ee4")
    .staggerFrom('#feedback-3 .br-speech-buble-wrapper__content', bubleAnimeSpeed, {
        autoAlpha: 0,
        //x: +100,
        ease: Power4.easeInOut
    }, 0.1, "ee4")

    .add("IS2end")
    .addPause()

    .add("IS3start")
    .staggerTo('#feedback-3 .br-speech-buble-wrapper__author', bubleAnimeSpeed, {
        autoAlpha: 0,
        x: +100,
        ease: Power4.easeInOut
    }, 0.1, "ee5")
    .staggerTo('#feedback-3 .br-speech-buble-wrapper__content', bubleAnimeSpeed, {
        autoAlpha: 0,
        //x: -100,
        ease: Power4.easeInOut
    }, 0.1, "ee5")

    .to("#IS2", 0.001, {backgroundColor: "transparent", ease: Power3.easeOut})
    .to("#IS3", 0.001, {backgroundColor: "#3b53d1", ease: Power3.easeOut})

    .to($feedback_3, 0.1, {className: "-=active_buble"})
    .to($feedback_4, 0.1, {className: "+=active_buble"})
    .staggerFrom('#feedback-4 .br-speech-buble-wrapper__author', bubleAnimeSpeed, {
        autoAlpha: 0,
        x: -100,
        ease: Power4.easeInOut
    }, 0.1, "ee6")
    .staggerFrom('#feedback-4 .br-speech-buble-wrapper__content', bubleAnimeSpeed, {
        autoAlpha: 0,
        //x: +100,
        ease: Power4.easeInOut
    }, 0.1, "ee6")

    .add("IS3end")

    .addPause()

    .add("IS4start")
    .staggerTo('#feedback-4 .br-speech-buble-wrapper__author', bubleAnimeSpeed, {
        autoAlpha: 0,
        x: +100,
        ease: Power4.easeInOut
    }, 0.1, "ee7")
    .staggerTo('#feedback-4 .br-speech-buble-wrapper__content', bubleAnimeSpeed, {
        autoAlpha: 0,
        //x: -100,
        ease: Power4.easeInOut
    }, 0.1, "ee7")

    .to("#IS3", 0.001, {backgroundColor: "transparent", ease: Power3.easeOut})
    .to("#IS4", 0.001, {backgroundColor: "#3b53d1", ease: Power3.easeOut})

    .to($feedback_4, 0.1, {className: "-=active_buble"})
    .to($feedback_5, 0.1, {className: "+=active_buble"})
    .staggerFrom('#feedback-5 .br-speech-buble-wrapper__author', bubleAnimeSpeed, {
        autoAlpha: 0,
        x: -100,
        ease: Power4.easeInOut
    }, 0.1, "ee8")
    .staggerFrom('#feedback-5 .br-speech-buble-wrapper__content', bubleAnimeSpeed, {
        autoAlpha: 0,
        //x: +100,
        ease: Power4.easeInOut, onComplete: function () {
            feedbackEndFlagIndicator = true;
            //feedbackFlagIndicator = false;

            console.log(feedbackFlagIndicator);
        }, onReverseComplete: function () {
            feedbackEndFlagIndicator = false;
            //feedbackFlagIndicator = false;

            console.log(feedbackFlagIndicator);
        }}, 0.1, "ee8")

    .add("IS4end");


//Check timelien direction
var forward = false;


function setFeed() {
    var bom = jQuery('.active_buble').next('.br-speech-buble-wrapper').find('.br-speech-buble-wrapper__author p').html();
    //console.log(bom);
    jQuery('#nextFeedback').html(bom);
}

setFeed();

function nextFeed() {
    var bom = jQuery('.active_buble').next('.br-speech-buble-wrapper').next('.br-speech-buble-wrapper').find('.br-speech-buble-wrapper__author p').html();
    //console.log(bom);
    if($('#nextFeedback').hasClass('hovered')){
        TweenMax.to('#nextFeedback', 0.3, {autoAlpha: 0, onComplete: function () {
            jQuery('#nextFeedback').html(bom);
            TweenMax.to('#nextFeedback', 0.3, {autoAlpha: 1});
        }});
    }else{
        jQuery('#nextFeedback').html(bom);
    }


}


function prevFeed() {
    var bom = jQuery('.active_buble').prev('.br-speech-buble-wrapper').prev('.br-speech-buble-wrapper').find('.br-speech-buble-wrapper__author p').html();
    //console.log(bom);
    if($('#nextFeedback').hasClass('hovered')){
        TweenMax.to('#nextFeedback', 0.3, {autoAlpha: 0, onComplete: function () {
            jQuery('#nextFeedback').html(bom);
            TweenMax.to('#nextFeedback', 0.3, {autoAlpha: 1});
        }});
    }else{
        jQuery('#nextFeedback').html(bom);
    }

}



function goBackward() {
    //console.log("Going backward");
    forward = true;
    var bom = jQuery('#feedback-5').prev('.br-speech-buble-wrapper').find('.br-speech-buble-wrapper__author p').html();
    jQuery('#nextFeedback').html(bom);
}
function goForward() {
    //console.log("Reversing to forward");
    forward = false;
    var bom = jQuery('#feedback-1').next('.br-speech-buble-wrapper').find('.br-speech-buble-wrapper__author p').html();
    jQuery('#nextFeedback').html(bom);

}


/*
 *
 * Navigate through feedbacks
 *
 */

jQuery(document).ready(function ($) {

    /*$('#nextFeedback').on('click', function(){
        console.log('click');
        if (forward === false && stopFloat.hasClass('activeHover')) {
            timeline_feedback.reverse();
            prevFeed();


        } else if(forward === true && stopFloat.hasClass('activeHover')) {
            timeline_feedback.play();
            nextFeed();

        }
    });*/



});


/*
*
*
* Animate first buble
*
* */

tlk
    .staggerFrom('#feedback-1 .br-speech-buble-wrapper__author', 0.4, {autoAlpha: 0, y: '+=40'})
    .to('#start', 1.2, { autoAlpha: 1, morphSVG: '#end', ease: Elastic.easeOut.config(1, 0.3)}, "Same lol")
    .to('#start', 1.2, { autoAlpha: 1, ease: Power4.easeOut}, "Same lol")
    .staggerFrom("#feedback-1 .br-speech-buble-wrapper__content p", 0.6, {
        autoAlpha: 0
    });
