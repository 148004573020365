/**
 * Created by mkbeectrl on 28/01/2017.
 */
var TlForms = new TimelineMax({paused: true});
TlForms.set('.revWrapper', {y: '+=40'});
TlForms.set('.forwardor', {y: '+=40'});



!function () {
    var stepsArray = document.querySelectorAll('.stepForm form .step'),
        reversorH = jQuery('.revWrapper').outerHeight() + 20,
        power4Ease = Power4.easeOut;

    //Steps transitions
    for (var i = 0; i < stepsArray.length; i++) {
        stepsArray[i].id = 'step' + i;

        if (i === 0) {

            TlForms.add(TweenMax.staggerFrom('#step' + i, 0.5, {autoAlpha: 0, y: '+=20', ease: Power4.easeOut}));
            TlForms.add(TweenMax.to('.forwardor', 0.35, {autoAlpha: 1, y: '-=40', ease: Power4.easeOut, transformOrigin: 'center center'}));
        } else if(i === 1){
            TlForms.add(TweenMax.to('#step' + (i - 1), 0.35, {autoAlpha: 0, x: '-=50', ease: Power4.easeOut}));
            TlForms.add(TweenMax.staggerFrom('#step' + i, 0.35, {autoAlpha: 0, x: '+=50', ease: Power4.easeOut}, '-=0.3'));
            //TlForms.add(TweenMax.to('.forwardor', 0.4, {autoAlpha: 1, x: '+=20'}));
            TlForms.add(TweenMax.to('.revWrapper', 0.35, {autoAlpha: 1, y: '-=40', ease: Power4.easeOut, transformOrigin: 'center center'}));
        }else if(i === 4){
            TlForms.add(TweenMax.to('#step' + (i - 1), 0.35, {autoAlpha: 0, x: '-=50'}));
            TlForms.add(TweenMax.staggerFrom('#step' + i, 0.5, {autoAlpha: 0, x: '+=50', ease: Power4.easeOut}, '-=0.3'));
            TlForms.add(TweenMax.to('.forwardor', 0.35, {autoAlpha: 0, x: '-=40', ease: Power4.easeOut , transformOrigin: 'center center'}));
            TlForms.add(TweenMax.to('#qSender', 0.35, { top: '-' + reversorH, width: '250%', autoAlpha: 1, ease: Power4.easeOut}));
        }else {
            TlForms.add(TweenMax.to('#step' + (i - 1), 0.35, {autoAlpha: 0, x: '-=50', ease: Power4.easeOut}));
            TlForms.add(TweenMax.staggerFrom('#step' + i, 0.5, {autoAlpha: 0, x: '+=50', ease: Power4.easeOut}, '-=0.3'));

        }

        //Add pause after each step
        TlForms.addPause();


    }


}();


/*
 *
 * Click functions
 *
 * */
var playForwardButtons = document.getElementsByClassName("canPlayFormsTimelineForward");

var playFormsTimeline = function () {
    TlForms.play();
    console.log('Playing!');
};

for (var i = 0; i < playForwardButtons.length; i++) {
    playForwardButtons[i].addEventListener('click', playFormsTimeline, false);
}

var playBackwardButtons = document.getElementsByClassName("canPlayFormsTimelineBackwards");

var playFormsTimelineBackward = function () {
    TlForms.reverse();
    console.log('Playing!');
};

for (var i = 0; i < playBackwardButtons.length; i++) {
    playBackwardButtons[i].addEventListener('click', playFormsTimelineBackward, false);
}


/*
 *
 * Input effects
 *
 * */

$('#qForm').on('keyup keypress', function(e) {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
        e.preventDefault();
        return false;
    }
});

var qformInput = $('.qFromInput');



qformInput.focus(function() {

    var placeholder = jQuery(this).prev('span.fakePlaceholder');
    TweenMax.to(placeholder, 0.2, {opacity:0, y: '-=20'});
    TweenMax.to($(this), 0.3, {backgroundColor: '#2c408f'})
});

qformInput.focusout(function() {

    var placeholder = jQuery(this).prev('span.fakePlaceholder');

    if(!jQuery(this).val()){

        if(jQuery(this).hasClass('iAmEmpty')){
            TweenMax.to(placeholder, 0.1, {opacity:1, y: '+=20', clearProps: 'all'});
        }else{
            TweenMax.to(placeholder, 0.1, {opacity:1, y: '+=20', clearProps: 'all'});
            TweenMax.to($(this), 0.3, {backgroundColor: '#3b53d1'});
        }
    }else{
        if(jQuery(this).hasClass('iAmEmpty')){
            jQuery(this).removeClass('iAmEmpty');
            TweenMax.to($(this), 0.3, {backgroundColor: '#3b53d1'});
        }else{
            TweenMax.to($(this), 0.3, {backgroundColor: '#3b53d1'});
        }

    }
});

/*
 *
 * Button Navigation
 *
 * */
/*function playInnerTL(e) {
    e.preventDefault();
    TlForms.play();
}

function reverseInnerTL(e) {
    e.preventDefault();
    if (TlForms.time() > 1)
        TlForms.reverse(null, false);
}

$('#qForm').find('.qFromInput').on('keyup', function (e) {
    if (event.shiftKey && e.keyCode == 13 || event.shiftKey && e.keyCode == 9) {
        reverseInnerTL(e);
        console.log('Reversing');
    }
    else {
        if (e.keyCode == 13 || e.keyCode == 9) {
            playInnerTL(e);
        }

    }
});*/

/*
 *
 * Falling stars
 *
 * */

TweenLite.set("#quoteWrapper",{perspective:600});

var total = 30;
var warp = document.getElementById("quoteWrapper"),	w = window.innerWidth , h = window.innerHeight;



function animm(elm){
    TweenMax.to(elm,R(6,15),{y:h+100,ease:Linear.easeNone,repeat:-1,delay:-15});
    TweenMax.to(elm,R(4,8),{x:'+=100',repeat:-1,yoyo:true,ease:Sine.easeInOut});
    TweenMax.to(elm, R(0.6,3), {autoAlpha: 1})
};

function R(min,max) {return min+Math.random()*(max-min)};

function stars(){
    for (i=0; i<total; i++){
        var Div = document.createElement('div');
        TweenLite.set(Div,{attr:{class:'star'},x:R(0,w),y:R(-200,-150),z:R(-200,200)});
        warp.appendChild(Div);
        animm(Div);
    }
}

/*
 *
 * Success & error
 *
 * */

var errorTimeline = new TimelineMax({paused: true}),
    successTimeline = new TimelineMax({paused: true}),
    messageWrapper = $('#quote-form-messages'),
    stepWrapper = $('.stepWrapper'),
    navButtonsWrapper = $('.navButtonsWrapper'),
    allInputs = qformInput.prev('span.fakePlaceholder');

successTimeline.to(stepWrapper, 1, {opacity: 0, y:'-70'}, 'Hula hop')
    .to(navButtonsWrapper, 1, {opacity:0, y: '+=70'}, 'Hula hop')
    .to(allInputs, 0.1, {clearProps: 'all'})
    .fromTo(messageWrapper, 1, {opacity: 0}, {opacity: 1, y: '-=50px' });


errorTimeline.fromTo(messageWrapper, 1, {opacity: 0, y: '-=50px'}, {opacity: 1, y: '-=220px' , ease: Power4.easeOut});

