
/*
 *
 * Show quote timeline
 *
 */
jQuery(document).ready(function ($) {
    var close = $("#closeQuoteForm"),
        $quoteFormButton = $('#showQuote'),
        leftGif = $('.br-gif--left'),
        rightGif = $('.br-gif--right');


    close.bind('click', function () {
        //console.log('click');

        showQuoteTl.reverse();
        TlForms.pause(0);
    });

    $quoteFormButton.bind('click', function () {
        showQuoteTl.play();
    });
});

showQuoteTl
    .to($quoteWrapper, 0.35, {
        zIndex: 1000,
        backgroundColor: 'rgba(53, 83, 209, 0.95)',
        height: '100vh',
        x: '0%',
        autoAlpha: 1,
        ease: Circ.easeOut,
        onComplete: function () {

            document.removeEventListener("mousewheel", Scroller);
            document.removeEventListener("DOMMouseScroll", Scroller);
        },
        onReverseComplete: function () {
            document.addEventListener("mousewheel", Scroller);
            document.addEventListener("DOMMouseScroll", Scroller);
        }
    }, 0.1, "Tween quote")
    .to('#showQuote', 0.35, {y: '-=40px', autoAlpha: 0, ease: Circ.easeOut}, 0.1, "Tween quote")
    .from('#closeQuoteForm', 0.8, {y: '-=80px', autoAlpha: 0, ease: Power4.easeInOut })
    .to($quoteForm, 0.45, {autoAlpha: 1, ease: Back.easeOut.config(1.7), onComplete: function () {
        TlForms.play();
    }});


var quoteContent = new TimelineMax({paused: true});

/*quoteContent
    .from('#quoteForm h1', 1, {autoAlpha: 0, y: "-40", ease: Power4.easeInOut})
    .staggerFrom('#quoteForm label', 2, {autoAlpha: 0, x: "+40", ease: Power4.easeInOut}, 0.1, "Content")
    .staggerFrom('#quoteForm .br-input', 2, {autoAlpha: 0, width: 0, ease: Power4.easeInOut}, 0.1, "Content")
    .from('#quoteGif', 0.5, {
        autoAlpha: 0,
        ease: Power4.easeInOut
    });*/