var timeline_main;
var feedbackFlagIndicator = false;
var feedbackEndFlagIndicator = false;
var mainTimeLineFlagIndicator = true;
var goToNextFeedbackVisible = false;
var switchFeedbacks = false;

function mainTimeline() {


    timeline_main = new TimelineMax({reversed: false, paused: false});

    timeline_main.add("start")
        .to('#loader', 0.1, {display: 'block'})
        .addPause()
        .add("S0start")
        .to($section_one, 0.4, {y: '+=40', autoAlpha: 0, ease: Power4.easeInOut})
        .add("S0end")
        .to(leftGif, 0.6, {
            autoAlpha: 0, ease: Power4.easeInOut, x: '-=80'}, 0.3, "S2")
        .to(rightGif, 0.6, {
            autoAlpha: 0, ease: Power4.easeInOut, x: '+=80'}, 0.3, "S2")
        .add("S1start")
        .to("#S0", 0.001, {backgroundColor: "transparent", ease: Power3.easeOut})
        .to("#S1", 0.001, {
            backgroundColor: "#3b53d1", ease: Power3.easeOut, onComplete: function () {
                $('#secondary-left').remove();
                $('#secondary-right').remove();
                if(!$('.br-gif--image').length){
                    create_image('secondary-left','br-gif--image','/assets/images/BOTH_MAN2.gif', 'Gif', 'Morphing Man', document.getElementById('br-gif--secondary-left'));
                    create_image('secondary-right','br-gif--image','/assets/images/BOTH_WOMAN2.gif', 'Gif', 'Morphing Woman', document.getElementById('br-gif--secondary-right'));
                    TweenMax.set('.br-gif--secondary img', {autoAlpha: 1});
                    TweenMax.set('.br-gif--secondary', {autoAlpha: 0});
                    console.log("Creating in section 2")
                }

            }, onReverseComplete: function () {
                $('#secondary-left').remove();
                $('#secondary-right').remove();
            }
        })





        .staggerFrom($section_two, 0.4, {y: '+=40', autoAlpha: 0, ease: Power4.easeInOut}, 0.1, "S13131")

        .staggerFrom('.br-gif--secondary', 0.6, {autoAlpha: 0, y: '-=50', ease: Power4.easeInOut, delay: 0.2}, "First")
        .to('#arrow', 0.4, { autoAlpha: 0, y: '+=30'}, "First2")
        .to(imessagewrapper, 0.4, {
            autoAlpha: 1, delay: 0.15, onComplete: function () {

                /*
                Play bubble floating effect
                 */
                goToNextFeedbackVisible = true;

                imessage_tl.play();
            },
            onReverseComplete: function () {
                goToNextFeedbackVisible = false;
            }
        }, "First2")
        .add("S1end")
        .addPause()


        //.add("S2start")
        .staggerTo('.sec-two-els', 0.6, {
            autoAlpha: 0, ease: Power4.easeInOut, y: '-=80'}, 0.3, "S231231")
        .add("S2start")

        .to("#S2", 0.001, {backgroundColor: "#3b53d1", ease: Power3.easeOut, onReverseComplete: function () {
            feedbackFlagIndicator = false;
            switchFeedbacks = false;
            goToNextFeedbackVisible = true;
        }, onComplete: function () {
            feedbackFlagIndicator = true;
            switchFeedbacks = true;
            goToNextFeedbackVisible = false;
        }})
        .to("#S1", 0.001, {backgroundColor: "transparent", ease: Power3.easeOut})
        .staggerFrom('#innerDotWrapper', 0.4, {autoAlpha: 0, height: 0}, "S22")
        .staggerFrom($section_three, 0.4, {y: '+=50', autoAlpha: 0, ease: Power4.easeInOut, onComplete: function () {

            tlk.play();

        }, onReverseComplete: function () {


            if(!$('.br-gif--image').length){
                create_image('secondary-left','br-gif--image','/assets/images/BOTH_MAN2.gif', 'Gif', 'Morphing Man', document.getElementById('br-gif--secondary-left'));
                create_image('secondary-right','br-gif--image','/assets/images/BOTH_WOMAN2.gif', 'Gif', 'Morphing Woman', document.getElementById('br-gif--secondary-right'));
                TweenMax.set('.br-gif--secondary img', {autoAlpha: 1});
                console.log("Creating in section 3");

            }



        }}, "S22")

        .add("S2end")
        .addPause()

        .to('#innerDotWrapper', 0.4, {
            autoAlpha: 0, height: 0, onComplete: function () {
                imessage_tl.pause(0);
                //console.log("Pause");
                feedbackFlagIndicator = false;
            }, onReverseComplete: function () {
                imessage_tl.play();
                feedbackFlagIndicator = true;
                console.log(feedbackFlagIndicator);
                //console.log("Trying to play on reverse");
            }
        }, "S3")
        .to(imessagewrapper, 0.4, {autoAlpha: 0, ease: Back.easeOut.config(1.7)}, "S3")
        .to($section_three, 0.4, {y: '-=40', autoAlpha: 0, ease: Power4.easeInOut}, "S3")
        .add("S3start")
        .to('#arrow', 0.3, {autoAlpha: 1, y: '-=30'})
        .to("#S2", 0.001, {backgroundColor: "transparent", ease: Power3.easeOut}, "lol1")
        .to("#S3", 0.001, {backgroundColor: "#3b53d1", ease: Power3.easeOut}, "lol1")

        .staggerFrom($section_four, 0.4, {
            y: '+=50',
            autoAlpha: 0, ease: Power4.easeInOut, onComplete: function () {
                if (jQuery(window).width() <= 768) {
                    timeline_s3_mobile.play();
                    //console.log("Playing for mobile")
                } else {
                    timeline_s3.play();
                }
            }
        }, "S33")
        .add("S3end")
        .addPause()

        .to('#arrow', 0.3, {autoAlpha: 0})
        .to($section_four, 0.4, {
            autoAlpha: 0, ease: Power4.easeInOut, onReverseComplete: function () {
                if (jQuery(window).width() <= 768) {
                    timeline_s3_mobile.play();

                    //console.log("Playing for mobile")
                } else {
                    timeline_s3.play();
                }
            }
        })
        .add("S4")
        //.to('#showQuote', 0.35, {y: '-=40px', autoAlpha: 0, ease: Power4.easeInOut})
        .to("#S3", 0.001, {backgroundColor: "transparent", ease: Power3.easeOut}, "lol22222")

        .to("#S4", 0.001, {backgroundColor: "#3b53d1", ease: Power3.easeOut}, "lol22222")

        .staggerFrom($section_five, 0.4, {
            y: '+=50', autoAlpha: 0, ease: Power4.easeInOut, onComplete: function () {
                timeline_s4.play();
                if(!$('.br-gif--image').length){
                    console.log('Creating in section 5');
                    create_image('secondary-left','br-gif--image','/assets/images/BOTH_MAN2.gif', 'Gif', 'Morphing Man', document.getElementById('br-gif--secondary-left'));
                    create_image('secondary-right','br-gif--image','/assets/images/BOTH_WOMAN2.gif', 'Gif', 'Morphing Woman', document.getElementById('br-gif--secondary-right'));
                    TweenMax.set('.br-gif--secondary img', {autoAlpha: 1});
                    TweenMax.set('.br-gif--secondary', {autoAlpha: 0});
                }
            }
        }, "Change class4")
        .to('.br-gif--secondary-left', 0.6, {
            autoAlpha: 1, ease: Power4.easeInOut, y: '+=70' }, "S22222")
        .to('.br-gif--secondary-right', 0.6, {
            autoAlpha: 1, ease: Power4.easeInOut, y: '+=70'}, "S22222")

        .add("end");

}

