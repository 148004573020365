/*
 *
 *
 * Create nav dots in the top right corner and allow pointo navigation
 *
 *
 */
!function () {
    var slides = document.querySelectorAll('.br-section--anime'),
        gifs = document.querySelectorAll('.br-gif'),
        slide3 = document.querySelector('.br-section--three'),
        slide3innerSecs = slide3.querySelectorAll('.br-inner-section'),
        tl = new TimelineMax({paused: true});


    //Navigation dots
    var dot = [];
    for (var i = 0; i < slides.length; i++) {
        dot[i] = document.createElement('div');

        dot[i].className = 'br-dot';
        dot[i].id = 'S' + i;
        document.getElementById('dotWrapper').appendChild(dot[i]);

        if (typeof window.addEventListener === 'function') {
            (function (_i) {
                dot[_i].addEventListener('click', function () {
                    if(_i === 1){
                        goToNextFeedbackVisible = false;
                        timeline_main.tweenFromTo("S" + _i + "start", "S" + _i + "end");
                    }
                    else if (_i >= 2 && _i < 4) {
                        timeline_main.tweenFromTo("S" + _i + "start", "S" + _i + "end");
                        //console.log("S"+_i+"start", "S"+_i+"end");
                    } else if (_i === 0) {
                        timeline_main.pause(0);
                        timeline_s1.tweenFromTo("player", "end");
                    } else if (_i === 4) {
                        timeline_main.tweenFromTo("S4", "end");
                        //console.log("Only last");
                    }
                });
            })(i);
        }
    }

    /*
     *
     * Create and append inner navigation dots
     *
     */

    var dotInner = [];
    var el = document.createElement("div");
    el.id = 'innerDotWrapper';
    //el.className ='ishidden' ;
    var div = document.getElementById('S2');
    insertAfter(div, el);

    for (var h = 0; h < slide3innerSecs.length; h++) {
        dotInner[h] = document.createElement('div');

        dotInner[h].className = 'br-dot--inner';
        dotInner[h].id = 'IS' + h;

        //console.log("Inner dots created");

        //console.log("Inner sections number: " + slide3innerSecs.length);


        document.getElementById('innerDotWrapper').appendChild(dotInner[h]);

        if (typeof window.addEventListener === 'function') {
            (function (_h) {
                dotInner[_h].addEventListener('click', function () {
                    if (_h >= 1 && _h < 4) {
                        timeline_feedback.tweenFromTo("IS" + _h + "start", "IS" + _h + "end");

                        //console.log("S"+_i+"start", "S"+_i+"end");
                    } else if (_h === 0) {
                        timeline_feedback.pause(0);
                    } else if (_h === 4) {
                        timeline_feedback.tweenFromTo("IS"+_h+"start", "IS"+ _h + "end");
                        //console.log("Only last");
                    }
                    //console.log("Let's ride!");
                });
            })(h);
        }
    }

}();