/*
 *
 *
 * Hide address bar for mobile (not sure if working properly on all devices)
 *
 *
 */
function hideAddressBar() {
    //console.log("Hide address bar");
    if (!window.location.hash) {
        if (document.height < window.outerHeight) {
            document.body.style.height = (window.outerHeight + 50) + 'px';
        }

        setTimeout(function () {
            window.scrollTo(0, 1);
        }, 50);
    }
}

window.addEventListener("load", function () {
    if (!window.pageYOffset) {
        hideAddressBar();
    }
});
window.addEventListener("orientationchange", hideAddressBar);


/*
 *
 *
 * Append element in the desired place in html
 *
 *
 */
function insertAfter(referenceNode, newNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}


/*
 *
 *
 * Global vars
 *
 *
 */
var $boxes_s1 = document.querySelectorAll('.br-box-s1'),
    $section_one = document.querySelectorAll('.br-section--one'),
    $section_two = document.querySelectorAll('.br-section--two'),
    $section_three = document.querySelectorAll('.br-section--three'),
    $section_four = document.querySelectorAll('.br-section--four'),
    $section_five = document.querySelectorAll('.br-section--five'),
    $feedbacks = document.querySelectorAll('.br-speech-buble-wrapper'),
    $feedback_1 = document.getElementById('feedback-1'),
    $feedback_2 = document.getElementById('feedback-2'),
    $feedback_3 = document.getElementById('feedback-3'),
    $feedback_4 = document.getElementById('feedback-4'),
    $feedback_5 = document.getElementById('feedback-5'),
    leftGif = document.querySelectorAll('.br-gif--left'),
    rightGif = document.querySelectorAll('.br-gif--right'),
    imessagewrapper = document.querySelectorAll('.br-imessage-wrapper'),
    $quoteWrapper = document.getElementById('quoteWrapper'),
    $quoteForm = document.getElementById('quoteForm'),
    $sceneWrapper = document.getElementById('sceneWrapper'),
    $imessage_ind = document.getElementById('imessage_ind'),

    tlk = new TimelineMax({reversed: false, paused: true}),
    showQuoteTl = new TimelineMax({reversed: false, paused: true}),
    showQuoteTl2 = new TimelineMax({reversed: false, paused: true}),
    imessage_tl = new TimelineMax({reversed: false, paused: true}),
    timeline_feedback = new TimelineMax({
        reversed: false,
        paused: true,
        onComplete: goBackward,
        onReverseComplete: goForward
    });

TweenMax.to('#arrow', 2, {y: '-=10', ease: Back.easeIn.config(1.7), yoyo: true, repeat: -1});


/*
 *
 *
 * Set tweens
 *
 *
 */
function oneTimeTweens() {

    TweenMax.set(imessagewrapper, {autoAlpha: 0});


    //TweenMax
    imessage_tl.to($imessage_ind, 0.9, {
        y: "+=14px",
        yoyo: true,
        repeat: -1,
        ease: Power2.easeOut
    });

// END OF TURNING OFF
}


/*
 *
 * Init functions
 *
 * */

TweenMax.to('#loader', 1, {autoAlpha: 0, ease: Power4.easeInOut});

var timeline_s1 = new TimelineMax({reversed: false, paused: true});
function welcomeTimeline() {


    //timeline_s1.add(TweenMax.to('#loader', 1, {autoAlpha: 0, ease: Power4.easeInOut}));
    timeline_s1.add("player");
    timeline_s1.add(TweenMax.to($boxes_s1, 0.1, {
        onComplete: function () {
            jQuery('#secondary-left').remove();
            jQuery('#secondary-right').remove();
        }
    }));
    timeline_s1.add(TweenMax.fromTo($boxes_s1, 1, {y: '-=40', autoAlpha: 0}, {
        y: '+=40', autoAlpha: 1, ease: Power4.easeInOut, clearProps: "all", onReverseComplete: function () {
            jQuery('#secondary-left').remove();
            jQuery('#secondary-right').remove();
        }
    }));

    timeline_s1.add("end");
}


//set positions of some elements
welcomeTimeline();
setTimeout(function () {

    timeline_s1.play();

}, 200);
oneTimeTweens();
mainTimeline(); //launch main timeline


jQuery(document).ready(function ($) {

    $("#S0").css({
        'background-color': "#3b53d1"
    });
    $("#IS0").css({
        'background-color': "#3b53d1"
    });


    /*
     *
     * Sorry timeline
     *
     * */
    var sorry_timeline = new TimelineMax({paused: true}),
        sceneW = $('#sceneWrapper'),
        screenWidth = sceneW.outerWidth(),
        playSorry = 480;

    sorry_timeline
        .to("#sorry", 0.35, {
            zIndex: 1000,
            backgroundColor: 'rgba(53, 83, 209, 1)',
            height: '100vh',
            x: '0%',
            autoAlpha: 1,
            ease: Circ.easeOut
        }, 0.1, "Tween quote");


    if (sceneW.outerWidth() > sceneW.outerHeight() && sceneW.outerHeight() < playSorry) {
        sorry_timeline.play();
    }


    $(window).resize(function () {
        console.log('On resize');
        sorry_timeline.pause(0);
        if (sceneW.outerWidth() > sceneW.outerHeight() && sceneW.outerHeight() < playSorry && sceneW.outerWidth() !== screenWidth) {
            sorry_timeline.play();
        } else if (sceneW.outerWidth() < sceneW.outerHeight() && sceneW.outerWidth() !== screenWidth) {
            location.reload();
        }
        else if ((sceneW.outerWidth() < screenWidth || sceneW.outerWidth() > screenWidth) && sceneW.outerWidth() > sceneW.outerHeight()) {
            location.reload();
        }
    });


});

/*
 *
 * Click functions
 *
 * */
var stopFloat = $('.br-imessage-wrapper');

document.getElementById('arrow').addEventListener('click', function () {

    if (feedbackFlagIndicator === true) {

        if (feedbackEndFlagIndicator === false) {
            timeline_feedback.play();
            nextFeed();
        } else if (feedbackEndFlagIndicator === true) {
            timeline_main.play();
        }
    } else {
        timeline_main.play();
    }
    console.log("Playing!");

});


document.getElementById('logo').addEventListener('click', function () {

    timeline_main.pause(0);
    timeline_feedback.pause(0);
    timeline_s1.tweenFromTo("player", "end");
    console.log("Playing from begining!");

});

/*
 Create img element
 */

function create_image(id, className, src, alt, title, appendTo) {
    var img = new Image();
    img.src = src;
    if (alt != null) img.alt = alt;
    if (title != null) img.title = title;

    img.className = className;
    img.id = id;

    appendTo.appendChild(img);

    return img;
}


/*
 Imessage indicator hover effects
 */

jQuery(document).ready(function ($) {


    $('#arrowLike p').on('click', function () {

        if ($(this).hasClass('clickable')) {
            if (feedbackFlagIndicator === true) {

                if (feedbackEndFlagIndicator === false && forward === false) {
                    timeline_feedback.play();
                    nextFeed();
                } else if (feedbackEndFlagIndicator === true && forward === true) {
                    timeline_feedback.reverse();
                    prevFeed();
                }
            } else {
                timeline_main.play();
            }
            console.log("Playing!");
        }


    });

    var alreadyTriggered = false;

    function hideIt() {
        if (!$('p#nextFeedback').hasClass('active')) {


                TweenMax.to('#arrowLike p', 0.3, {
                    autoAlpha: 0, onComplete: function () {
                        TweenMax.set('#arrowLike p', {display: 'none', clearProps: 'all'});
                        TweenMax.to(' #imessage_ind', 0.6, {
                            bottom: "45px", ease: Power4.easeInOut, onComplete: function () {

                                imessage_tl.play();
                            }
                        });
                        $('#arrowLike p').removeClass('clickable');
                        alreadyTriggered = false
                    }
                });


        }

    }



    var supportsTouch = false;
    if ('ontouchstart' in window) {
        //iOS & android
        supportsTouch = true;
    } else if(window.navigator.msPointerEnabled) {
        //Win8
        supportsTouch = true;
    }


    stopFloat.hover(function () {

        if(supportsTouch === false){
            alreadyTriggered = true;

            if (goToNextFeedbackVisible === true && switchFeedbacks === false && alreadyTriggered === true) {

                imessage_tl.progress(1, false);
                imessage_tl.pause(1);
                TweenMax.to('#imessage_ind', 0.6, {
                    bottom: "75px", ease: Power4.easeInOut, onComplete: function () {
                        TweenMax.set('p#go', {
                            display: 'block', onComplete: function () {
                                TweenMax.to('p#go', 0.3, {autoAlpha: 1});
                                console.log('Visibility change');
                                setTimeout(function () {
                                    $('p#go').addClass('clickable');


                                }, 300);
                                alreadyTriggered = false;
                            }
                        });
                    }
                });


            } else if (goToNextFeedbackVisible === false && switchFeedbacks === true && alreadyTriggered === true && !$('p#nextFeedback').hasClass('active')) {

                imessage_tl.progress(1, false);
                imessage_tl.pause(1);
                TweenMax.to('#imessage_ind', 0.6, {
                    bottom: "75px", ease: Power4.easeInOut, onComplete: function () {
                        $('p#nextFeedback').addClass('active');
                        TweenMax.set('p#nextFeedback', {
                            display: 'block', onComplete: function () {
                                TweenMax.to('p#nextFeedback', 0.3, {autoAlpha: 1});
                                console.log('Visibility change');
                                setTimeout(function () {
                                    $('p#nextFeedback').addClass('clickable');
                                }, 300);
                                $('p#nextFeedback').removeClass('active');


                                alreadyTriggered = false;
                            }
                        });
                    }
                });
            }
        }



    }, hideIt);

    stopFloat.on('touchstart', function () {

        if(supportsTouch === true){
            alreadyTriggered = true;

            if (goToNextFeedbackVisible === true && switchFeedbacks === false && alreadyTriggered === true) {

                imessage_tl.progress(1, false);
                imessage_tl.pause(1);
                TweenMax.to('#imessage_ind', 0.6, {
                    bottom: "75px", ease: Power4.easeInOut, onComplete: function () {
                        TweenMax.set('p#go', {
                            display: 'block', onComplete: function () {
                                TweenMax.to('p#go', 0.3, {autoAlpha: 1});
                                console.log('Visibility change');
                                setTimeout(function () {
                                    $('p#go').addClass('clickable');



                                }, 300);
                                setTimeout(function () {
                                    hideIt();
                                }, 3000);
                                alreadyTriggered = false;
                            }
                        });
                    }
                });


            } else if (goToNextFeedbackVisible === false && switchFeedbacks === true && alreadyTriggered === true && !$('p#nextFeedback').hasClass('active')) {

                imessage_tl.progress(1, false);
                imessage_tl.pause(1);
                TweenMax.to('#imessage_ind', 0.6, {
                    bottom: "75px", ease: Power4.easeInOut, onComplete: function () {
                        $('p#nextFeedback').addClass('active');
                        TweenMax.set('p#nextFeedback', {
                            display: 'block', onComplete: function () {
                                TweenMax.to('p#nextFeedback', 0.3, {autoAlpha: 1});
                                console.log('Visibility change');
                                setTimeout(function () {
                                    $('p#nextFeedback').addClass('clickable');
                                }, 300);

                                setTimeout(function () {
                                    hideIt();
                                }, 3000);
                                $('p#nextFeedback').removeClass('active');


                                alreadyTriggered = false;
                            }
                        });
                    }
                });
            }
        }



    });


});
